import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecurityGuardService } from './services/security-guard.service';
import { ResponsiveRepairsDashboardComponent } from './components/dashboards/responsive-repairs/responsive-repairs.component';
import { SectorScorecardComponent } from './components/dashboards/sector-scorecard/sector-scorecard.component';
import { AnalyseComponent } from './components/analyse/analyse-component/analyse.component';
import { ReportComponent } from './components/reports/report-component/report.component';
import { DataSetsComponent } from './components/data-sets/data-sets.component';
import { AnnualSummaryDashboardComponent } from './components/dashboards/annual-summary/annual-summary-dashboard.component';
import { TenantSatisfactionDashboardComponent } from './components/dashboards/tenant-satisfaction/tenant-satisfaction-dashboard.component';
import { GlobalAccountsDashboardComponent } from './components/dashboards/global-accounts/global-accounts-dashboard.component';
import { QuarterlyPerformanceDashboardComponent } from './components/dashboards/quarterly-performance/quarterly-performance.component';
import { RentCollectionDashboardComponent } from './components/dashboards/rent-collection/rent-collection.component';
import { VoidsAndLettingsComponent } from './components/dashboards/voids-and-lettings/voids-and-lettings.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { VfmDashboardComponent } from './components/dashboards/vfm/vfm-dashboard.component';
import { BespokeDashboardComponent } from './components/dashboards/bespoke/bespoke-dashboard/bespoke-dashboard.component';
import { ESGComponent } from './components/dashboards/esg/esg.component';
import { TSMDashboardComponent } from './components/dashboards/tsm/tsm-dashboard.component';

const routes: Routes = [
    { path: '', redirectTo: 'annual-summary-dashboard', pathMatch: 'full' },
    { path: 'report/:id', component: ReportComponent, canActivate: [SecurityGuardService] },
    { path: 'responsive-repairs-dashboard', component: ResponsiveRepairsDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'analyse', component: AnalyseComponent, canActivate: [SecurityGuardService] },
    { path: 'sector-scorecard-dashboard', component: SectorScorecardComponent, canActivate: [SecurityGuardService] },
    { path: 'data-sets', component: DataSetsComponent, canActivate: [SecurityGuardService] },
    { path: 'annual-summary-dashboard', component: AnnualSummaryDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'tenant-satisfaction-dashboard', component: TenantSatisfactionDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'global-accounts-dashboard', component: GlobalAccountsDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'quarterly-performance-dashboard', component: QuarterlyPerformanceDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'rent-collection-dashboard', component: RentCollectionDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'voids-and-lettings-dashboard', component: VoidsAndLettingsComponent, canActivate: [SecurityGuardService] },
    { path: 'esg-dashboard', component: ESGComponent, canActivate: [SecurityGuardService] },
    { path: 'tsm-dashboard', component: TSMDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'vfm-dashboard', component: VfmDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'bespoke-dashboard', component: BespokeDashboardComponent, canActivate: [SecurityGuardService] },
    { path: 'unauthorized', component: UnauthorizedComponent }
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }
