import { LayoutService } from '../../../services/layout.service';
import { ReportLayout } from '../../../models/reports/report-layout';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterState } from '@angular/router';
import { TileMetaData } from '../../../models/tile-metadata';
import { ReportSection } from '../../../models/reports/report-section';
import { ReportChartData } from '../../../models/reports/report-chart-data';
import { TilesRequest } from '../../../models/tiles-request';
import { ChartsService } from '../../../services/charts.service';
import { ReportEditTileOptions } from '../../../models/edit-tile-options';
import { ReportChartMetaData } from '../../../models/reports/report-chart-metadata';
import { ComponentCharts } from '../../../models/component-charts';
import { ChartType } from '../../../models/enum/chart-type';
import { SectionChartIndex } from '../../../models/reports/section-chart-index';
import { HideableOption } from '../../../models/options/hideable-option';
import { IsSelectableOption } from '../../../models/options/is-selectable-option';
import { ReportDialogueSection } from '../../../models/report-dialogue-section';
import { ReportDialogueComponent } from '../../../models/report-dialogue-component';
import { ReportChosenOptions } from '../../../models/chosen-options';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { LayoutType } from '../../../models/enum/layout-type';
import { UserLayout } from '../../../models/user-preferences/user-layout';
import { ExportType } from '../../../models/enum/export-type';
import { JsReportPage } from '../../../models/export/js-report-page';
import { JsReportService } from '../../../services/js-report.service';
import { ExportService } from '../../../services/export.service';
import { Options } from '../../../highcharts/options';
import { Subscription } from 'rxjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'report',
    templateUrl: './report.component.html'
})
export class ReportComponent implements OnInit, OnDestroy {
    reportName: string = '';
    reportId: number = 0;
    showDownloadModal: boolean = false;
    showReorderModal: boolean = false;
    modalSections: ReportDialogueSection[] = [];
    sectionOptions: IsSelectableOption<number>[] = [];
    showAddSectionModal: boolean = false;
    deleteModel: SectionChartIndex | undefined;
    showEdit = false;
    showDeleteModal = false;
    peersSubscription!: Subscription;
    layoutSaveSubscription!: Subscription;
    reportLayout!: ReportLayout;
    editOptions = new ReportEditTileOptions();
    loadReportLayoutSubscription!: Subscription;

    constructor(private layoutService: LayoutService, private spinnerService: SpinnerService, private peersService: PeersService,
        private toastr: ToastrService, private route: ActivatedRoute, private chartsService: ChartsService, private myAccountService: MyAccountService,
        private jsReportService: JsReportService, private exportService: ExportService) {

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.reportId = +params['id'];
                this.getInitialReport();
            }
        });
    }

    ngOnInit() {
        this.peersSubscription = this.peersService.peersChanged.subscribe((fullReload: boolean) => {
            if (fullReload) return this.getInitialReport();

            return this.getTileDataForReport();
        });

        this.layoutSaveSubscription = this.layoutService.saveReportLayout.subscribe((name: string) => {
            this.spinnerService.show('app-spinner');
            const tilesRequest = this.createTileRequest();

            this.layoutService.addLayout(name, this.reportId, tilesRequest, LayoutType.Report).
                subscribe({ next: (layout: UserLayout) => {
                    this.myAccountService.addToLayoutList(layout, this.reportName);
                    this.toastr.success(`Your report layout, ${layout.name}, was successfully saved`, 'Success');
                }, error: () => {
                    this.toastr.error('Please try again', 'A problem occured when saving your report layout');
                }, complete: () => {
                    this.spinnerService.hide('app-spinner');
                }});
        });

        this.loadReportLayoutSubscription = this.layoutService.loadReportLayout.subscribe(() => this.getInitialReport());
    }

    getInitialReport() {
        const layoutId = this.layoutService.reportLayoutId;
        this.spinnerService.show('app-spinner');
        this.layoutService.getReportLayout(this.reportId, layoutId).subscribe({next:(layout: ReportLayout) => {
            this.reportLayout = layout;
            this.reportName = layout.title;
        }, error: () => {
            this.toastr.error('Please try again', 'An error occured when loading your report');
        }, complete: () => this.spinnerService.hide('app-spinner')});
    }

    ngOnDestroy(): void {
        this.peersSubscription.unsubscribe();
        this.layoutSaveSubscription.unsubscribe();
        this.loadReportLayoutSubscription.unsubscribe();
    }

    toggleSection(id: number) {
        const sect = document.getElementById(`section-${id}`);

        if(!sect) return; 

        if (sect.classList.contains('hide')) sect.classList.remove('hide');
        else sect.classList.add('hide');
    }

    isSectionHidden(id: number): boolean {
        const section = document.getElementById(`section-${id}`);

        if (section && section.classList.contains('hide')) {
            return true;
        }

        return false;
    }

    getTileDataForReport() {
        const currentTiles = this.createTileRequest();

        this.spinnerService.show('app-spinner');
        this.chartsService.getDataForTiles(currentTiles).subscribe({ next: (tilesRequest: TilesRequest) => {

            tilesRequest.tiles.forEach((tmd: TileMetaData) => {
                this.applyTile(tmd);
            });

            this.spinnerService.hide('app-spinner');
        }, error: () => {
            this.spinnerService.hide('app-spinner');
            this.toastr.error('Please try again', 'An error occurred when updating your report');
        }});
    }

    private applyTile(tmd: TileMetaData) {
        const identifiers = tmd.dashboardItemRef.split('-');
        const sect = this.reportLayout.sections.find((rs: ReportSection) => rs.id === Number(identifiers[0]));

        if(!sect) return;

        let item = sect.selectedComponents[Number(identifiers[1])];

        if (item) item.data = tmd.data;
        else {
            item = new ReportChartData();
            const selectedMetaData = sect.availableComponents.find(ac => ac.calcRef === tmd.calcRef);

            this.updateChart(item, tmd.data, ChartType[tmd.chartType], selectedMetaData!);

            sect.selectedComponents.push(item);
        }
    }

    private createTileRequest() {
        const tilesRequest = new TilesRequest();

        this.reportLayout.sections.forEach((rs: ReportSection) => {
            const tiles = rs.selectedComponents.map((rcd: ReportChartData, index: number) => {
                const tmd = new TileMetaData();
                tmd.calcRef = rcd.calcRef;
                tmd.chartType = rcd.selectedKpiChartType;
                tmd.dashboardItemRef = `${rs.id}-${index}`;
                return tmd;
            });
            tilesRequest.tiles.push(...tiles);
        });
        return tilesRequest;
    }

    applyEdit(value: ReportChosenOptions) {
        this.spinnerService.show('app-spinner');
        this.chartsService.getChartData(value.chartType, value.calcRef).subscribe({
            next: (res: Options) => {
                const section = this.reportLayout.sections.find(sect => sect.id === value.sectionId)!;
                const selectedMetaData = section.availableComponents.find(ac => ac.calcRef === value.calcRef)!;

                let selectedValue: ReportChartData;
                if (value.chartIndex < 0) {
                    selectedValue = new ReportChartData();
                    section.selectedComponents.push(selectedValue);
                } else {
                    selectedValue = section.selectedComponents[value.chartIndex];
                }

                this.updateChart(selectedValue, res, value.chartType, selectedMetaData);

                this.showEdit = false;
                this.closeAddSection();
                this.spinnerService.hide('app-spinner');
            },
            error: () => {
                this.spinnerService.hide('app-spinner');
                this.toastr.error('Please try again', 'An error occured when updating your report');
            },
            complete: () => {
                this.spinnerService.hide('app-spinner')
            }
        });
    }

    updateChart(reportChartData: ReportChartData, options: Options, chartType: ChartType, metaData: ReportChartMetaData) {
        reportChartData.data = options;
        reportChartData.selectedKpiChartType = ChartType[chartType];
        reportChartData.definition = metaData.definition;
        reportChartData.name = metaData.name;
        reportChartData.units = metaData.units;
        reportChartData.calcRef = metaData.calcRef;
    }

    closeEditModal() {
        if (this.sectionOptions && this.sectionOptions.length > 0) this.openAddSection();
        this.showEdit = false;
    }

    addChart(sectionId: number) {
        this.openEditModal(new SectionChartIndex(sectionId, -1));
    }

    openEditModal(sectionChartIndex: SectionChartIndex) {
        const section = this.reportLayout.sections.find(sect => sect.id === sectionChartIndex.sectionId);

        if(!section) return; 

        const editTileOptions = new ReportEditTileOptions();

        editTileOptions.sectionId = section.id;
        editTileOptions.chartIndex = sectionChartIndex.chartIndex;

        section.availableComponents.forEach((rcmd: ReportChartMetaData, i: number) => {

            let selected = false;
            if(editTileOptions.chartIndex && editTileOptions.chartIndex > -1) {
                const chart = section.selectedComponents[sectionChartIndex.chartIndex];
                selected = rcmd.calcRef === chart.calcRef;
            }
            
            editTileOptions.piOptions.push(new HideableOption<number>(selected, rcmd.name, rcmd.calcRef, true, rcmd.noDataMessage));

            const componentCharts = new ComponentCharts();
            componentCharts.chartTypes = rcmd.availableChartTypes.split(', ').map(act => ChartType[act]);
            componentCharts.calcRef = rcmd.calcRef;

            if (selected) {
                const ct = section.selectedComponents[sectionChartIndex.chartIndex].selectedKpiChartType;
                componentCharts.selectedChartType = ChartType[ct];
            } else {
                componentCharts.selectedChartType = componentCharts.chartTypes[0];
            }

            editTileOptions.chartsTypes.push(componentCharts);
        });

        if(sectionChartIndex.chartIndex > 0) {
            const currentlySelectedChart = section.selectedComponents[sectionChartIndex.chartIndex];
            const optionForSelectedChart = editTileOptions.piOptions.find(option => option.value === currentlySelectedChart.calcRef);
            
            if (optionForSelectedChart) optionForSelectedChart.isSelected = true;        
        }

        if (editTileOptions.piOptions.length > 0 && editTileOptions.piOptions.every(option => !option.isSelected)) {
            editTileOptions.piOptions[0].isSelected = true;
        }

        this.editOptions = editTileOptions;
        this.showEdit = true;
    }

    deleteItem(value: SectionChartIndex) {
        const section = this.reportLayout.sections.find(sect => sect.id === value.sectionId);
        section!.selectedComponents.splice(value.chartIndex, 1);
        this.closeDeleteModal();
    }

    openDeleteModal(value: SectionChartIndex) {
        this.deleteModel = value;
        this.showDeleteModal = true;
    }

    closeDeleteModal() {
        this.deleteModel = undefined;
        this.showDeleteModal = false;
    }

    openAddSection() {
        this.sectionOptions = this.reportLayout.sections.map(sect => {
            return new IsSelectableOption<number>(sect.selectedComponents.length === 0,
                false,
                sect.name,
                sect.id);
        });

        const firstAvailableSection = this.reportLayout.sections.find(sect => sect.selectedComponents.length === 0)!.id;
        const firstAvailableSectionOption = this.sectionOptions.find(so => so.value === firstAvailableSection);
        firstAvailableSectionOption!.isSelected = true;

        this.showAddSectionModal = true;
    }

    addSection(sectionId: number) {
        this.openEditModal(new SectionChartIndex(sectionId, -1));
        this.showAddSectionModal = false;
    }

    closeAddSection() {
        this.showAddSectionModal = false;
        this.sectionOptions = [];
    }

    noChartsSelected(sectionId: number): boolean {
        return this.reportLayout.sections.find(sect => sect.id === sectionId)!.selectedComponents.length === 0;
    }

    allSectionsPopulated(): boolean {
        return this.reportLayout.sections.every(sect => !this.noChartsSelected(sect.id));
    }

    openReorderModal() {
        this.buildModalSections();

        this.showReorderModal = true;
    }

    private buildModalSections() {
        this.modalSections = new Array<ReportDialogueSection>();
        this.reportLayout.sections.forEach(rs => {
            if (rs.selectedComponents.length > 0) {
                const dialogueSection = new ReportDialogueSection();
                dialogueSection.id = rs.id;
                dialogueSection.sectionName = rs.name;
                dialogueSection.hideComponents = true;
                dialogueSection.components = rs.selectedComponents.map((rcd, index) => {
                    const dialogueComponent = new ReportDialogueComponent();
                    dialogueComponent.componentName = rcd.name;
                    dialogueComponent.index = index;
                    dialogueComponent.calcRef = rcd.calcRef;
                    return dialogueComponent;
                });

                this.modalSections.push(dialogueSection);
            }
        });
    }

    saveOrder() {
        this.reportLayout.sections.forEach(rs => {
            const reportSectionDialog = this.modalSections.find(rds => rs.id === rds.id);

            if (!reportSectionDialog) return;

            if (reportSectionDialog.isSelected) {
                const reportSectionComponents = new Array<ReportChartData>();

                reportSectionDialog.components.forEach(rdc => {

                    if (rdc.isSelected) reportSectionComponents.push(rs.selectedComponents[rdc.index]);
                });

                rs.selectedComponents = reportSectionComponents;
            } else {
                rs.selectedComponents = new Array<ReportChartData>();
            }
        });

        this.reportLayout.sections.sort((left: ReportSection, right: ReportSection) => {
            const leftIndex = this.modalSections.findIndex(rds => rds.id === left.id);
            const rightIndex = this.modalSections.findIndex(rds => rds.id === right.id);

            if (leftIndex < rightIndex) return -1;
            return 1;
        });

        this.closeReorderModal();
    }

    closeReorderModal() {
        this.modalSections = [];
        this.showReorderModal = false;
    }

    closeDownloadModal() {
        this.showDownloadModal = false;
    }

    openDownloadModal() {
        this.buildModalSections();
        this.showDownloadModal = true;
    }

    downloadReport(exportType: ExportType) {
        if (exportType === ExportType.Excel) this.downloadExcelReport();
        else this.downloadPdfReport();
    }

    downloadPdfReport() {
        const mainContents = this.buildReportContent();

        const fullReport = this.jsReportService.buildFinalReport(mainContents, this.modalSections, this.reportLayout.sections);
        const fileName = `${this.reportLayout.title}.pdf`;

        this.spinnerService.show('app-spinner', `\'${fileName}\' generating`, 'It could take up to 2 minutes for the document to download');
        this.exportService.exportReportAsPDF(this.reportLayout.title, fullReport).subscribe({
            next: (res: Blob) => {
                this.spinnerService.hide('app-spinner', true);

                this.jsReportService.startDownload(fileName, res);
            }, 
            error: () => this.spinnerService.hide('app-spinner', true)}
        );

        this.showDownloadModal = false;
    }

    downloadExcelReport() {
        const calcRefs = this.jsReportService.getExportCalcRefs(this.modalSections);
        this.spinnerService.show('app-spinner');
        const fileName = `${this.reportLayout.title}.xlsx`;
        
        this.exportService.exportExcel(fileName, ...calcRefs).subscribe(() => {
            this.spinnerService.hide('app-spinner');
            this.closeDownloadModal();
        });
    }

    private buildReportContent(): JsReportPage[] {
        const pages = new Array<JsReportPage>();

        let pageNum = 0;
        this.modalSections.filter(rds => rds.isSelected).forEach((rds: ReportDialogueSection, index: number) => {
            const selectedComponents = rds.components.filter(component => component.isSelected);

            if (selectedComponents.length === 0) return;
            pageNum++;

            const sectionPage = new JsReportPage(`<div class='report-page report-section-name'>
                                                  <div class='page-number'>${pageNum}</div>
                                                  <span>${rds.sectionName}</span>
                                                  </div>`);

            pages.push(sectionPage);

            const chunkSize = 3;
            const chunksCount = Math.ceil(selectedComponents.length / chunkSize);

            for (let i = 0; i < chunksCount; i++) {
                pageNum++;
                const page = this.buildCurrentChunkHtml(pageNum, i, chunkSize, selectedComponents, rds);

                pages.push(page);
            }
        });

        return pages;
    }

    private buildCurrentChunkHtml(pageNum: number, chunkIndex: number, chunkSize: number, selectedComponents: ReportDialogueComponent[], rds: ReportDialogueSection) {
        let chartsHtml = `<div class='report-page report-section-charts'>
                                  <div class='page-number'>${pageNum}</div>`;
        const startIndex = chunkIndex * chunkSize;
        const endIndex = startIndex + 3;
        const chunk = selectedComponents.slice(startIndex, endIndex);
        chunk.forEach(rdc => {
            chartsHtml = this.getChartElement(rds, rdc, chartsHtml);
        });

        chartsHtml = chartsHtml.replace(/^\s*|\n|<!--[\s\S]*?-->|lg-/gm, '');
        chartsHtml = chartsHtml.replace(/£/gm, '&pound;');

        chartsHtml += '</div>';
        const page = new JsReportPage(chartsHtml);
        return page;
    }

    private getChartElement(rds: ReportDialogueSection, rdc: ReportDialogueComponent, chartsHtml: string) {
        const chart = document.getElementById(`${rds.id}-${rdc.index}`)!.cloneNode(true) as Element;

        const highchartsContainers = chart.getElementsByClassName('highcharts-container');
        const svgs = chart.getElementsByClassName('highcharts-root');
        this.removeUnwantedAttributes(highchartsContainers);
        this.removeUnwantedAttributes(svgs);
        chartsHtml += chart.innerHTML;

        return chartsHtml;
    }

    private removeUnwantedAttributes(nodeList: HTMLCollectionOf<Element>) {
        for (let i = 0; i < nodeList.length; i++) {
            const container = nodeList[i];
            container.removeAttribute('style');
            container.removeAttribute('width');
            container.removeAttribute('height');
        }
    }
}
