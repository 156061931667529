import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../../services/layout.service';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { ExportService } from '../../../services/export.service';
import { IconInfoTileComponent } from '../../tiles/dashboard-tiles/icon-info-tile/icon-info-tile.component';
import { ChartsService } from '../../../services/charts.service';
import { RankedComboTileComponent } from '../../tiles/dashboard-tiles/ranked-combo-tile/ranked-combo-tile.component';
import { PeersService } from '../../../services/peers.service';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'rent-collection-dashboard',
    templateUrl: 'rent-collection.component.html'
})
export class RentCollectionDashboardComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.RentCollection;
    dashboardHTMLId = 'rent-collection-dashboard-body';
    portraitExport = false;

    @ViewChild('RCDashChart1') comboTile!: RankedComboTileComponent;
    @ViewChild('RCDashChart2') info1Tile!: IconInfoTileComponent;
    @ViewChild('RCDashChart3') info2Tile!: IconInfoTileComponent;
    @ViewChild('RCDashChart4') info3Tile!: IconInfoTileComponent;
    @ViewChild('RCDashChart5') info4Tile!: IconInfoTileComponent;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['RCDashChart1', this.comboTile],
            ['RCDashChart2', this.info1Tile],
            ['RCDashChart3', this.info2Tile],
            ['RCDashChart4', this.info3Tile],
            ['RCDashChart5', this.info4Tile]
        ]);

        this.Init();
    }
}
