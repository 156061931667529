<div class="container-fluid dashboard" id="voids-and-lettings-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="voids-and-lettings-dashboard-body">
        <div id="dashboard-export-wrapper">
            <div class="col-lg-6">
                <div class="col-left dashboard-contents">
                    <div class="tiles-container">
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #VLDashChart1></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #VLDashChart3></small-chart-tile>
                        <small-chart-tile (openEditEvent)="openEditModal($event)" #VLDashChart5></small-chart-tile>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="col-right dashboard-contents">
                    <div class="tiles-container">
                        <icon-info-tile (openEditEvent)="openEditModal($event)" #VLDashChart2></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)" #VLDashChart4></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)" #VLDashChart6></icon-info-tile>
                        <icon-info-tile (openEditEvent)="openEditModal($event)" #VLDashChart7></icon-info-tile>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>