<div class="container-fluid dashboard" id="vfm-dashboard">
    <dashboard-header [dashboardName]="dashboardName" [dashboardHTMLId]="dashboardHTMLId" (exportExcel)="exportExcel()"
        [dashboardId]="dashboardId" (exportPdf)="exportPdf()"></dashboard-header>
    <div class="dashboard-body" id="vfm-dashboard-body">
        <div id="dashboard-export-wrapper">

            <div id="quadrant" class="quadrant">
                <div class="quadrant-wrapper">
                    <div class="quadrant-wrapper-section red">
                        <span>Relatively poor performance <br /> High cost</span>
                    </div>
                    <div class="quadrant-wrapper-section yellow">
                        <span>Relatively good performance <br /> High cost</span>
                    </div>
                    <div class="quadrant-wrapper-section yellow">
                        <span>Relatively poor performance <br /> Low cost</span>
                    </div>
                    <div class="quadrant-wrapper-section green">
                        <span>Relatively good performance <br /> Low cost</span>
                    </div>
                    <div class="quadrant-point-container">
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart1"
                            ngbTooltip="Responsive repairs">1</div>
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart2"
                            ngbTooltip="Voids and lettings">2</div>
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart3"
                            ngbTooltip="Rent arrears and collection">3</div>
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart4"
                            ngbTooltip="Tenancy management">4</div>
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart5"
                            ngbTooltip="Resident engagement">5</div>
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart6"
                            ngbTooltip="Customer services">6</div>
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart7"
                            ngbTooltip="Neighbourhood management">7</div>
                        <div class="quadrant-point hide" (click)="openSection($event)" id="VFMDashChart8"
                            ngbTooltip="Community investment">8</div>
                    </div>
                </div>
            </div>

            <span class="quadrant-point-info info-block"><span class="fas fa-info-circle"></span> Points will be displayed on the quadrant when there are 8 or more peers in a PI's sample</span>

            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'1'"
                [sectionName]="'Responsive repairs'" #VFMDashChart1></dashboard-section>
            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'2'"
                [sectionName]="'Voids and lettings'" #VFMDashChart2></dashboard-section>
            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'3'"
                [sectionName]="'Rent arrears and collection'" #VFMDashChart3></dashboard-section>
            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'4'"
                [sectionName]="'Tenancy management'" #VFMDashChart4></dashboard-section>
            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'5'"
                [sectionName]="'Resident engagement'" #VFMDashChart5></dashboard-section>
            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'6'"
                [sectionName]="'Customer services'" #VFMDashChart6></dashboard-section>
            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'7'"
                [sectionName]="'Neighbourhood management'" #VFMDashChart7></dashboard-section>
            <dashboard-section (openEditEvent)="openEditModal($event)" (modelUpdated)="drawPoint($event)" [index]="'8'"
                [sectionName]="'Community investment'" #VFMDashChart8></dashboard-section>
        </div>
    </div>
</div>

<edit-tile-modal *ngIf="showEdit" (closeModal)="closeEditModal()" (applyEdit)="applyEdit()" [model]="editOptions">
</edit-tile-modal>