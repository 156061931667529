export enum DateRangeSection {
    PerformanceData = 310001,
    SectorScorecard = 310002,
    BenchmarkingOptions = 310003,
    LandlordOrganisationCharacteristics = 310004,
    UnitStockEntry = 310005,
    NonpayCost = 310006,
    EmployeeApportionments = 310007,
    Development = 310008,
    STAR = 310009,
    HeadlineCostBenchmarking = 310010,
    JointCosts = 310011,
    ReportingPreferences = 310012
}