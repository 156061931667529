import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseTile } from '../../tiles/dashboard-tiles/base-tile/base-tile';
import { LayoutService } from '../../../services/layout.service';
import { ToastrService } from 'ngx-toastr';
import { ChartsService } from '../../../services/charts.service';
import { PeersService } from '../../../services/peers.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { SmallChartTileComponent } from '../../tiles/dashboard-tiles/small-chart-tile/small-chart-tile.component';
import { DashboardComponent } from '../base-dashboard/dashboard.component';
import { ExportService } from '../../../services/export.service';
import { IconInfoTileComponent } from '../../tiles/dashboard-tiles/icon-info-tile/icon-info-tile.component';
import { MyAccountService } from '../../../services/my-account.service';
import { JsReportService } from '../../../services/js-report.service';
import { DashboardId } from '../../../models/enum/dashboard-id';

@Component({
    selector: 'voids-and-lettings-dashboard',
    templateUrl: './voids-and-lettings.component.html'
})
export class VoidsAndLettingsComponent extends DashboardComponent implements AfterViewInit {
    dashboardId = DashboardId.VoidsLettings;
    dashboardHTMLId = 'voids-and-lettings-dashboard-body';
    portraitExport = false;

    @ViewChild('VLDashChart1') left1Tile!: SmallChartTileComponent;
    @ViewChild('VLDashChart3') left2Tile!: SmallChartTileComponent;
    @ViewChild('VLDashChart5') left3Tile!: SmallChartTileComponent;
    @ViewChild('VLDashChart2') right1Tile!: IconInfoTileComponent;
    @ViewChild('VLDashChart4') right2Tile!: IconInfoTileComponent;
    @ViewChild('VLDashChart6') right3Tile!: IconInfoTileComponent;
    @ViewChild('VLDashChart7') right4Tile!: IconInfoTileComponent;

    constructor(layoutService: LayoutService, chartsService: ChartsService, peersService: PeersService, jsReportService: JsReportService,
        spinnerService: SpinnerService, toastr: ToastrService, exportService: ExportService, myAccountService: MyAccountService) {
        super(layoutService, chartsService, peersService, jsReportService, spinnerService, toastr, exportService, myAccountService);
    }

    ngAfterViewInit() {
        this.componentMap = new Map<string, BaseTile>([
            ['VLDashChart1', this.left1Tile],
            ['VLDashChart3', this.left2Tile],
            ['VLDashChart5', this.left3Tile],
            ['VLDashChart2', this.right1Tile],
            ['VLDashChart4', this.right2Tile],
            ['VLDashChart6', this.right3Tile],
            ['VLDashChart7', this.right4Tile]
        ]);

        this.Init();
    }

}
